import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { AppLayoutComponent } from "./shared/components/layout/app.layout.component";
import { LoginGuard } from './shared/guards/login.guard';
import { AuthGuard } from './shared/guards/auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            { 
                path: 'login', 
                loadChildren: () => import('../app/pages/auth/auth.module').then(m => m.AuthModule),
                canActivate: [LoginGuard]
            },
            { 
                path: 'forgot-password', 
                loadChildren: () => import('../app/pages/forget-password/forget-password.module').then(m=> m.ForgetPasswordModule),
                canActivate: [LoginGuard]

            },
            { 
                path: 'set-password', 
                loadChildren: () => import('../app/pages/set-password/set-password.module').then(m => m.SetPasswordModule),
                canActivate: [LoginGuard]

            },
            { 
                path: 'reset-password', 
                loadChildren: () => import('../app/pages/reset-password/reset-password.module').then(m=> m.ResetPasswordModule),
                canActivate: [LoginGuard]

            },

            {
                path: '', component: AppLayoutComponent,
                children: [
                    // { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
                    { path: '', loadChildren: () => import('../app/pages/pages.module').then(m => m.PagesModule) }
                ],
                canActivate: [AuthGuard]
            },
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/login' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
