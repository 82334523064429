import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(private _router: Router) {}

    canActivate(): boolean {
        const accessToken = localStorage.getItem('a_ad_token');
        const refreshToken = localStorage.getItem('r_ad_token');
        if (accessToken && refreshToken) {
            this._router.navigate(['/operator']);
            return false;
        }
        return true;
    }
}