import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private _http: HttpClient
  ) { }

  login(user: User): Observable<any> {
    return this._http.post('auth/login/', user);
  }

  refreshToken(token: any): Observable<any> {
    return this._http.post('auth/refresh/',
      { refresh: token }, { headers: { 'Access-Control-Allow-Origin': '*' } })
  }

  logout(refreshToken: any): Observable<any> {
    return this._http.post('auth/logout/',
      { refresh: refreshToken })
  }
}
