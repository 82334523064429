import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
      const accessToken = localStorage.getItem('a_ad_token');
      const refreshToken = localStorage.getItem('r_ad_token');
      if (accessToken && refreshToken) {
        return true;
      } else {
        this._router.navigate(['/login']);
        return false;
      }
    }
}