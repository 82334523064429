import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../../pages/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const authTokenStr = localStorage.getItem('a_ad_token');
    const authToken = authTokenStr ? JSON.parse(authTokenStr) : null;

      /* conditionally check for prod urls */
      const twindomScans = environment.production ?'https://twindom-scans-photos.s3.amazonaws.com/':'https://twindom-scans.s3.amazonaws.com/'
      const twindomPrevies = environment.production ?'https://twindom-preview.s3.amazonaws.com/':'https://twindom-previews.s3.amazonaws.com/'
      const s3Previes = environment.production? 'https://s3.amazonaws.com/twindom-preview' : 'https://s3.amazonaws.com/twindom-previews'

      
    if(req.url === 'https://open.er-api.com/v6/latest/USD') {} 

    else if(req.url.includes('https://s3.amazonaws.com/twindom-preview')) {}

    else if (req.url.includes(twindomScans) ){}     // -photos only prod
      else if (req.url.includes(twindomPrevies)){}    // remove s from preview for prod


    else if(req.url.includes(s3Previes)) {}
    // else if(req.url.includes('https://twindom-scans.s3.amazonaws.com')){} 
    
    else {
      req = req.clone({
        setHeaders: {
          Authorization: `bearer ${authToken}`
        },
        url: `${environment.base_url}${req.url}`
      });
    }

    // Check if it's the specific API call for image upload
    if (req.url.endsWith('/franchise/upload/')) {
      // Set empty headers for this specific API call
      req = req.clone({ headers: new HttpHeaders() });
    }
    const refreshTokenStr = localStorage.getItem('r_ad_token');
    const refreshToken = refreshTokenStr ? JSON.parse(refreshTokenStr) : null;

    let refreshAttempts = 0;
    const maxRefreshAttempts = 2;

    const handleRequest = (request: HttpRequest<any>): Observable<any> => {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.url === `${environment.base_url}auth/refresh/`) {
            localStorage.clear();
            this._authService.logout(refreshToken);
            this._router.navigate(['/login'])
          } else {
            if (error.status === 401 && refreshAttempts < maxRefreshAttempts && refreshToken) {
              refreshAttempts++;

              return this._authService.refreshToken(refreshToken).pipe(
                switchMap((response: any) => {
                  localStorage.setItem('a_ad_token', JSON.stringify(response.access));
                  refreshAttempts = 0;

                  const authReq = request.clone({
                    setHeaders: {
                      Authorization: `bearer ${response.access}`
                    },
                    url: `${req.url}`
                  });

                  return handleRequest(authReq);
                }),
                catchError((error: HttpErrorResponse) => {
                  if (error.status === 401) {
                    localStorage.clear();
                    this._authService.logout(refreshToken);
                  }
                  return throwError(error);
                })
              );
            } else if (error.status === 401) {
              localStorage.clear();
              this._authService.logout(refreshToken);
            }
          }

          return throwError(error);
        }),
      );
    }

    return handleRequest(req);
  }
}
