<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/images/logodark.png" alt="logo">
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>
    <div class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <p class="mr-4 flex align-items-center cursor-pointer" (click)="accountMenu.toggle($event)">My Account &nbsp; 
            <i class="pi pi-angle-down relative top-1"></i> 
        </p>
    </div>
    <p-menu #accountMenu [popup]="true" [model]="items"></p-menu>
</div>