import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "../../service/app.layout.service";
import { AuthService } from 'src/app/pages/auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {

    items: MenuItem[] = [];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        private _authService: AuthService,
        private _router: Router
    ) { }

    ngOnInit(): void {
        this.initializeItemsList()
    }

    initializeItemsList() {
        this.items = [
          {
            label: 'Account', icon: 'pi pi-cog', command: (event)=> {          
              this._router.navigate(['/account'])
            }
          },
          {
            label: 'Logout', icon: 'pi pi-fw pi-power-off', command: (event)=> {          
              this.logout()
            }
          },
        ];
      }

    logout() {
        const refreshTokenStr = localStorage?.getItem('r_ad_token');
        const refreshToken = refreshTokenStr ? JSON.parse(refreshTokenStr) : null;
        this._authService.logout(refreshToken).subscribe(res => {
            if (res?.error) {
            } else {
                localStorage.clear();
                this._router?.navigate(['/login']);
            }
        })
    }
}
